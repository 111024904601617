/* eslint-disable no-unused-vars */
import React, { useRef, useState, useEffect } from 'react';
import { HeadingTag, ModuleBase, ResponsiveImage } from '@/components';
import Flicking, { FlickingError, ViewportSlot } from '@egjs/react-flicking';
import '@egjs/flicking-plugins/dist/pagination.css';
import '@egjs/react-flicking/dist/flicking.css';
import { Pagination } from '@egjs/flicking-plugins';
import '@egjs/flicking-plugins/dist/pagination.css';
import tailwindConfig from '../tailwind.config.js';
import RestaurantLogoCarouselPagination from '@/components/general/RestaurantLogoCarouselPagination.jsx';
import FullBleedCard from '@/components/cards/FullBleedCard.jsx';
import Chevron from '/assets/chevron-left-white.svg';
import classNames from 'classnames';

const CardCarouselFocusFullBleedModule = ({ data }) => {
  const { cardRow, headingTitle } = data || {};
  const carousel = useRef();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  let progressPadding = 0;

  const willChange = (e) => {
    setCurrentIndex(e.index);
  };

  const onReady = () => {
    setTotalCount(cardRow[0].props.cards.length);
    flickingMove();
  };

  const calcStyleValue = (progressMax, progress, valueStart, valueEnd) => {
    const decreasingProgress = progressMax - progress;
    const progressTime = valueEnd - decreasingProgress - (valueEnd - progressMax) / (progressMax / decreasingProgress);
    const value = decreasingProgress * (valueStart / progressMax) + progressTime;
    console.log(decreasingProgress, 'decreasingProgress');
    return Math.max(value, valueEnd);
  };

  const flickingMove = () => {
    const panels = carousel.current.visiblePanels;
    panels.forEach((panel) => {
      const progress = Math.abs(panel.outsetProgress);
      if (progress !== 0 && progressPadding === 0) {
        const windowSize = window.innerWidth;
        if (windowSize < parseInt(tailwindConfig.theme.screens.md, 10)) {
          progressPadding = 0.85; // sm
        } else if (windowSize < parseInt(tailwindConfig.theme.screens.xl, 10)) {
          progressPadding = 0.8; // md
        } else {
          progressPadding = 0.65; // lg
        }
      }
      const target = panel.element;
      let imgOpacity;
      if (progress !== 0) {
        imgOpacity = calcStyleValue(progressPadding, progress, 1, 0.5);
      }
      target.style.opacity = imgOpacity;
    });
  };

  const move = async (dir) => {
    try {
      if (dir > 0) {
        await carousel.current.next();
      } else {
        await carousel.current.prev();
      }
    } catch (err) {
      if (!(err instanceof FlickingError)) {
        throw new Error(err);
      }
    }
  };

  useEffect(() => {
    carousel.current.on('ready', (e) => {
      e.currentTarget.addPlugins(
        new Pagination({
          type: 'bullet',
        }),
      );
    });
  }, []);

  return (
    <ModuleBase
      data={data}
      className="relative h-screen xxl:h-[700px] !py-0 flex flex-col justify-center overflow-hidden"
    >
      <div className="absolute top-0 left-0 z-10 w-full h-full pointer-events-none flex flex-col justify-between items-center py-6 lg:pt-8 lg:pb-10">
        {headingTitle && (
          <HeadingTag data={headingTitle} className="text-[16px] leading-[24px] font-medium text-white" />
        )}
      </div>

      {cardRow[0].props.cards.length > 1 && (
        <div className="chevron-wrapper  lg:flex w-full absolute flex justify-between z-50 max-w-[486px] left-1/2 -translate-x-1/2">
          <div
            className="p-5"
            onClick={() => {
              move();
            }}
          >
            <Chevron
              className={`chevron z-50 cursor-pointer ${cardRow[0].props.cards.length === 2 && currentIndex === 0 ? 'opacity-0 duration-300 transition-all pointer-events-none' : 'opacity-100 duration-300 transition-all'}`}
            />
          </div>
          <div
            className="p-5"
            onClick={() => {
              move(1);
            }}
          >
            <Chevron
              className={`chevron z-50 rotate-180 cursor-pointer ${cardRow[0].props.cards.length === 2 && cardRow[0].props.cards.length - 1 === currentIndex ? 'opacity-0 duration-300 transition-all pointer-events-none' : 'opacity-100 duration-300 transition-all'}`}
            />
          </div>
        </div>
      )}

      {cardRow.map((row, rIndex) => (
        <div
          key={rIndex}
          className="w-full max-w-[343px] max-h-[511px] lg:max-w-[486px] lg:max-h-[508px] h-full m-auto bg-primary/25 carousel-wrapper  backdrop-blur-[7.5px] z-10"
        >
          <Flicking
            ref={carousel}
            align="center"
            useResizeObserver={true}
            horizontal={true}
            circular={row.props.cards.length > 2 ? true : false}
            disableOnInit={row.props.cards.length === 1 ? true : false}
            circularFallback="bound"
            onMove={flickingMove}
            onWillChange={willChange}
            onReady={onReady}
            interruptable={false}
            className="h-full CardCarouselFocusFullBleedModule"
          >
            {row.props.cards.map((card, index) => (
              <div key={index} className="card flex relative  px-[30px] sm:px-0 w-full h-full overflow-hidden ">
                <FullBleedCard
                  data={card.props}
                  moduleHeadingTitle={headingTitle ?? ''}
                  moduleName={card.moduleName}
                  className={`fullbleed--card ${card.moduleName === 'HotelCard' ? 'mx-16 xl:mx-[202px]' : 'mx-0 xl:mx-0'}`}
                />
              </div>
            ))}
            <ViewportSlot>
              <div
                className={`flicking-pagination flex items-center justify-center lg:hidden mb-10 
                            [&_.flicking-pagination-bullet]:transition-all
                            [&_.flicking-pagination-bullet]:duration-200
                            [&_.flicking-pagination-bullet]:w-[4px] 
                            [&_.flicking-pagination-bullet]:h-[4px] 
                            [&_.flicking-pagination-bullet:last-child]:w-[3px] 
                            [&_.flicking-pagination-bullet:last-child]:h-[3px] 
                            [&_.flicking-pagination-bullet]:bg-white/40 
                            [&_.flicking-pagination-bullet-active]:!w-[5px]
                            [&_.flicking-pagination-bullet-active]:!h-[5px]
                            [&_.flicking-pagination-bullet-active]:!bg-white
                            `}
              ></div>
            </ViewportSlot>
          </Flicking>

          {cardRow[0].props.cards.length > 1 && (
            <RestaurantLogoCarouselPagination
              current={currentIndex}
              total={totalCount}
              className={`z-20 hidden lg:flex absolute left-1/2 bottom-10 -translate-x-1/2`}
            />
          )}
        </div>
      ))}

      <div className="absolute top-0 left-0 w-full h-full bg-slate-600">
        <div className="relative w-full h-full">
          {cardRow[0].props.cards.map((card, index) => (
            <div
              key={index}
              className={`absolute top-0 left-0 w-full h-full transition-opacity duration-[1000ms] ${index !== currentIndex ? 'opacity-0' : ''}`}
            >
              <ResponsiveImage
                key={index}
                image={card.props.image}
                widths={{ xs: 1024, sm: 1024, md: 1024, lg: 1024, xl: 1280, xxl: 1536 }}
                showImageCredit
              />
              <div
                className={classNames('absolute left-0 top-0 w-full h-full bg-black/30 duration-300 transition-all')}
              ></div>
            </div>
          ))}
        </div>
      </div>
    </ModuleBase>
  );
};
export default CardCarouselFocusFullBleedModule;
